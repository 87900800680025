.layout-normal {
    padding: calc(64px + 16px) 0 0 0;
}

.layout-search {
    padding: calc(64px + 87px + 16px) 0 0 0;
}

.layout-none {
    padding: 16px 0 0 0;
}