.supercard-order__icon-cancel {
    float: right;
    cursor: pointer;
}

.supercard-order__label-delete {
    font-family: Gotham;
    font-size: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #e20613;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 12px;
}

.supercard-order__title {
    font-family: Gotham;
    font-size: 30px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #171a20;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.supercard-order__input {
    width: 100%;
    height: 84px;
    border-radius: 100px;
    background: #fff;
    border: 1px solid #a2a3a5;
    font-family: Gotham;
    font-size: 30px;
    text-align: center;
    color: #171a20;
    margin-bottom: 24px;
}

.supercard-order__input::placeholder {
    font-family: Gotham;
    font-size: 30px;
    text-align: center;
    color: #a2a3a5;
}

.supercard-order__input:focus {
    border: 4px solid rgba(62, 107, 225, 0.75);
    outline: none;
}

.supercard-order__input::-webkit-outer-spin-button,
.supercard-order__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.supercard-order__input[type=number] {
    -moz-appearance: textfield;
}

.supercard-order__label {
    font-family: Gotham;
    font-size: 24px;
    text-align: center;
    color: #5c5e62;
    text-transform: uppercase;
    margin-bottom: 24px;
}

.supercard-order__textarea {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #a2a3a5;
    font-family: Gotham;
    font-size: 30px;
    text-align: center;
    color: #171a20;
    padding: 24px;
    margin-bottom: 24px;
}

.supercard-order__textarea::placeholder {
    font-family: Gotham;
    font-size: 30px;
    text-align: center;
    color: #a2a3a5;
}

.supercard-order__textarea:focus {
    border: 4px solid rgba(62, 107, 225, 0.75);
    outline: none;
}

.supercard-order__group-shortcut {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.supercard-order__button-shortcut {
    width: 100%;
    height: 84px;
    background: #fff;
    border: 1px solid #a2a3a5;
    border-radius: 100px;
    font-family: Gotham;
    font-size: 30px;
    text-align: center;
    color: #171a20;
    padding: 0 12px;
    margin-right: 18px;
    margin-bottom: 16px;
}


.supercard-order__button-shortcut:last-of-type {
    margin-right: 0;
}

.supercard-order__button-submit {
    width: 100%;
    height: 72px;
    border-radius: 100px;
    background: #3e6be1;
    border: none;
    font-family: Gotham;
    font-size: 36px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}

.supercard-order__button-submit--disabled {
    opacity: 0.5;
}