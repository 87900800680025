.login {
    height: 100vh;
    width: 100vw;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./assets/logo.svg');
    background-repeat: no-repeat;
    background-size: 372px 28.02px;
    background-position: center top 15%;
}

.login__card {
    max-width: 420px;
    min-height: 304px;
    border-radius: 20px;
    background: #fff;
    padding: 32px 24px;
}

.login__title {
    font-family: Gotham;
    font-size: 30px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #171a20;
    margin-bottom: 12px;
}

.login__sub-title {
    font-family: Gotham;
    font-size: 20px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #5c5e62;
    text-transform: uppercase;
    margin-bottom: 32px;
}

.login__input {
    width: 100%;
    height: 56px;
    border-radius: 28px;
    background: #fff;
    border: 1px solid #a2a3a5;
    font-family: Gotham;
    font-size: 20px;
    text-align: center;
    color: #5c5e62;
    margin-bottom: 24px;
    caret-color: #3E6BE1;
}

.login__input::placeholder {
    color: #A2A3A5;
}

.login__input:focus {
    border: 4px solid rgba(62, 107, 225, 0.75);
    outline: none;
}

.login__button {
    width: 100%;
    height: 56px;
    background: #3e6be1;
    border-radius: 100px;
    font-family: Gotham;
    font-size: 20px;
    text-align: center;
    color: #fff;
    border: none;
}

@media screen and (min-width: 0px) and (max-width: 420px) {
    .login {
        background-size: calc(100vw - 24px) auto;
        background-position: center top 15%;
    }
}