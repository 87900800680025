.enter {
    margin: 16px;
    padding: 32px 0;
    border-radius: 20px;
    background: #f4f4f4;
    box-shadow: 0px 0px 6px rgba(23, 26, 32, 0.16);
    position: relative;
}

.enter__icon-cancel {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.enter__title {
    font-family: Gotham;
    font-size: 24px;
    text-align: center;
    color: #5c5e62;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.enter__vehicle-plates {
    font-family: Gotham;
    font-size: 36px;
    text-align: center;
    color: #171a20;
    margin-bottom: 36px;
}

.enter__user-button {
    width: 100%;
    height: 146px;
    border-radius: 20px;
    background: #3e6be1;
    border: 3px solid rgba(62, 107, 225, 0.75);
    font-family: Gotham;
    font-size: 36px;
    color: #fff;
    text-align: center;
    margin: 12px 0;
}

.enter__user-button--active {
    opacity: 0.5;
}

.enter__submit-button {
    width: 100%;
    height: 92px;
    border-radius: 100px;
    background: #fff;
    border: 2px solid #e5e5ea;
    font-family: Gotham;
    font-size: 36px;
    color: #171a20;
    text-align: center;
    margin-top: 36px;
}