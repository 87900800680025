.search {
    position: fixed;
    top: 64px;
    right: 0;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: #E5E5EA;
    border-bottom: 1px solid #A2A3A5;
}

.search__icon-back {
    width: 23px;
    height: 18.242px;
    margin: 0 24px 0 8px;
    cursor: pointer;
}

.search__input {
    width: calc(100% - (23px + 24px + 8px));
    height: 54px;
    border-radius: 100px;
    background: #fff;
    border: 1px solid #a2a3a5;
    padding: 0 8px 0 16px;
    font-family: Gotham;
    font-weight: 500;
    font-size: 20px;
    color: #171a20;
    text-transform: uppercase;
    outline: none;
    box-shadow: none;
    caret-color: #3E6BE1;
}

.search__input:focus {
    border: 4px solid rgba(62, 107, 225, 0.75);
    outline: none;
}

.search__input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background: url('./assets/icon-reset.svg') no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
}

.search__input:focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
}

/* .search__results {
    white-space: nowrap;
    position: absolute;
    right: calc(16px + 16px + 30px + 16px);
    font-family: Gotham;
    font-weight: 500;
    font-size: 18px;
    color: #a2a3a5;
} */