.card-history {
    border-radius: 10px;
    background: #fff;
    margin-bottom: 24px;
    padding: 12px 0;
    cursor: pointer;
}

.card-history__group {
    padding: 12px 0;
}

.card-history__label-one {
    font-family: Gotham;
    font-size: 30px;
    text-align: left;
}

.card-history__label-two {
    font-family: Gotham;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 2px;
}

.card-history__label-three {
    font-family: Gotham;
    font-size: 24px;
    text-transform: uppercase;
}

.card-history__color-one {
    color: #171a20;
}

.card-history__color-two {
    color: #a2a3a5;
}

.card-history__color-three {
    color: #5c5e62;
}

.card-history__border {
    border: 1px solid #a2a3a5;
}