.supercard__icon-cancel {
    float: right;
    cursor: pointer;
}

.supercard__label-add {
    font-family: Gotham;
    font-size: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #3e6be1;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 9px;
}

.supercard__title {
    font-family: Gotham;
    font-size: 30px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #171a20;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.supercard__input {
    width: 100%;
    height: 54px;
    border-radius: 100px;
    background: #fff;
    border: 1px solid #a2a3a5;
    padding: 0 8px 0 16px;
    font-family: Gotham;
    font-size: 20px;
    color: #171a20;
    outline: none;
    box-shadow: none;
    caret-color: #3E6BE1;
    text-align: center;
    margin-bottom: 30px;
}

.supercard__input:focus {
    border: 4px solid rgba(62, 107, 225, 0.75);
    outline: none;
}

.supercard__input::placeholder {
    font-size: 20px;
    color: #a2a3a5;
}

.supercard__input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background: url('./assets/icon-reset.svg') no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
}

.supercard__input:focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
}