.card-supercard {
    width: 100%;
    height: auto;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;

    padding: 16px 0;
    margin-bottom: 24px;
}

.card-supercard__group {
    padding: 12px 0;
}

.card-supercard__label-one {
    font-family: Gotham;
    font-size: 18px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.card-supercard__label-two {
    font-family: Gotham;
    font-size: 30px;
    text-align: left;
}

.card-supercard__label-three {
    font-family: Gotham;
    font-weight: 500;
    font-size: 24px;
    text-align: left;
}

.card-supercard__color-one {
    color: #a2a3a5;
}

.card-supercard__color-two {
    color: #171a20;
}

.card-supercard__color-three {
    color: #5c5e62;
}

.card-supercard__border {
    border: 1px solid #a2a3a5;
}

.card-supercard__icon-add {
    margin-right: 16px;
    cursor: pointer;
}

.card-supercard__icon-edit {
    cursor: pointer;
}