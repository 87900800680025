.card-order {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 24px;
    padding: 12px 0;
}

.card-order__group {
    padding: 12px 0;
}

.card-order__border {
    border: 1px solid #a2a3a5;
}

.card-order-done {
    opacity: 0.5;
    box-shadow: none;
}

.card-order__label-one {
    font-family: GothamNarrow;
    font-size: 36px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.card-order__label-two {
    font-family: Gotham;
    font-size: 24px;

}

.card-order__label-three {
    font-family: Gotham;
    font-size: 18px;
    margin-bottom: 2px;
}

.card-order__color-one {
    color: #171a20;
}

.card-order__color-two {
    color: #5c5e62;
}

.card-order__color-three {
    color: #e20613;
}

.card-order__color-four {
    color: #3e6be1;
}

.card-order__ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-order__icon-edit {
    cursor: pointer;
}

.card-order__icon-checkmark {
    margin-left: 16px;
    cursor: pointer;
}

.card-order__flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.card-order__button-amount {
    position: relative;
    width: auto;
    height: 60px;
    border-radius: 6px;
    background: #3e6be1;
    border: none;
    font-family: Gotham;
    font-size: 36px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #fff;
    margin-left: 16px;
    padding: 0 38px 0 16px;
}

.card-order__span-amount {
    font-size: 14px;
    position: absolute;
    top: 16px;
}