.note {
    margin: 16px;
    padding: 32px 0;
    border-radius: 20px;
    background: #f4f4f4;
    box-shadow: 0px 0px 6px rgba(23, 26, 32, 0.16);
    position: relative;
}

.note__icon-cancel {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.note__title {
    font-family: Gotham;
    font-size: 24px;
    text-align: center;
    color: #5c5e62;
    text-transform: uppercase;
    margin-bottom: 36px;
}

.note__button-shotcut {
    width: 100%;
    height: 72px;
    border-radius: 36px;
    background: #fff;
    border: 1px solid #a2a3a5;
    font-family: Gotham;
    font-size: 24px;
    text-align: center;
    color: #171a20;
    margin: 12px 0;
}

.note__textarea {
    width: 100%;
    height: 328px;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #a2a3a5;
    margin: 12px 0;
    padding: 16px 24px;
    caret-color: #3E6BE1;
    font-family: Gotham;
    font-size: 32px;
    text-align: left;
    color: #171a20;
}

.note__textarea:focus {
    border: 4px solid rgba(62, 107, 225, 0.75);
    outline: none;
}

.note__button-submit {
    width: 100%;
    height: 72px;
    border-radius: 100px;
    background: #3e6be1;
    border: none;
    font-family: Gotham;
    font-size: 36px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    margin-top: 36px;
}

.note__button-submit--disabled {
    opacity: .5;
}

.note__button-reset {
    width: 100%;
    height: 72px;
    background: #fff;
    border: 1px solid #a2a3a5;
    border-radius: 100px;
    margin-top: 36px;
}

.note__button-reset--disabled {
    opacity: .5;
}

.note__icon-reset {
    cursor: pointer;
}