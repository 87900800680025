.order {
    position: relative;
    padding: 16px 0;
}

.order__button-note {
    height: 48px;
    width: auto;
    border-radius: 100px;
    background-color: transparent;
    border: none;
    font-family: Gotham;
    font-size: 20px;
    letter-spacing: -0.02em;
    color: #5c5e62;
    text-transform: uppercase;
    padding: 8px;
}

.order__button-note--active {
    background-color: #3e6be1;
    color: #fff;
}

.order__icon-cancel {
    float: right;
    cursor: pointer;
}

.order__title {
    font-family: Gotham;
    font-size: 36px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #171a20;
    text-transform: uppercase;
    margin-bottom: 32px;
}


.order__button-group-title {
    font-family: Gotham;
    font-size: 24px;
    text-align: center;
    color: #5c5e62;
    text-transform: uppercase;
    margin-bottom: 24px;
}

.order__button-group-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.order__button-group-two {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.order__button-one {
    width: 100%;
    height: 72px;
    background: #fff;
    border: 1px solid #a2a3a5;
    border-radius: 100px;
    font-family: Gotham;
    font-size: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #171a20;
    padding: 0 12px;
    margin-right: 18px;
    margin-bottom: 32px;
}

.order__button-one--active {
    border-color: transparent;
    outline: 6px #3e6be1 solid;
    outline-offset: -6px;
}

.order__button-one:last-of-type {
    margin-right: 0;
}

.order__button-two {
    width: 100%;
    height: 72px;
    border: none;
    border-radius: 20px;
    margin-right: 18px;
    margin-bottom: 32px;
}

.order__button-two--active {
    border-color: transparent;
    outline: 6px #3e6be1 solid;
    outline-offset: -6px;
}

.order__button-two:last-of-type {
    margin-right: 0;
}

.order__icon-no {
    cursor: pointer;
}

.order__icon-no--active {
    border-color: transparent;
    outline: 6px #E20613 solid;
    outline-offset: -6px;
}

.order__icon-yes {
    cursor: pointer;
}

.order__button-submit {
    width: 100%;
    height: 72px;
    border-radius: 100px;
    background: #3e6be1;
    border: none;
    font-family: Gotham;
    font-size: 36px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    margin-top: 18px;
}

.order__button-submit--disabled {
    opacity: 0.5;
}

/* ! novo */

.order__input-vehicle-plates {
    width: 100%;
    height: 84px;
    border-radius: 100px;
    background: #fff;
    padding: 0 16px 0 64px;
    border: none;
    font-family: Gotham;
    font-size: 36px;
    text-align: center;
    color: #171a20;
    text-transform: uppercase;
    caret-color: #3E6BE1;
}

.order__input-vehicle-plates::placeholder {
    color: #a2a3a5;
    text-transform: none;
}

.order__input-vehicle-plates:focus {
    border: none;
    outline: none;
}

.order__input-vehicle-plates::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 48px;
    height: 48px;
    background: url('./assets/icon-reset.svg') no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
}

.order__input-vehicle-plates:focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
}

.order__wrapper-vehicle-plates {
    max-height: 300px;
    width: 100%;
    background: #fff;
    border-radius: 42px;
    border: 1px solid #a2a3a5;
    overflow: scroll;
    margin-bottom: 32px;
}

.order__button-vehicle-plates {
    width: 100%;
    height: 76px;
    border-radius: 38px;
    background: #f5f5f5;
    font-family: Gotham;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #3e6be1;
    border: none;
    margin-bottom: 8px;
}

.order__group-vehicle-plates {
    padding: 0 16px;
}