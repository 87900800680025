@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham/Gotham-Medium.otf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'GothamNarrow';
    src: url('../fonts/GothamNarrow/GothamNarrow-Medium.otf');
    font-style: normal;
    font-weight: 500;
}

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    cursor: pointer;
}

body {
    font-family: 'Gotham', sans-serif;
    background-color: #E5E5EA;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

img, svg {
    vertical-align: middle;
}