.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    width: 100%;
    height: 64px;
    padding: 0 16px;
    background: #e20613;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar__logo {
    max-width: 100%;
    height: 32px;
    cursor: pointer;
}

.navbar__wrapper-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navbar__card {
    margin: 0 16px;
    cursor: pointer;
}

.navbar__user {
    margin-right: 16px;
    cursor: pointer;
}

.navbar__search {
    margin-right: 16px;
    cursor: pointer;
}

.navbar__add {
    cursor: pointer;
}