.dashboard__group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.dashboard__label-group {
    font-family: Gotham;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #5c5e62;
}

.dashboard__button-group {
    width: 260px;
    height: 42px;
    border-radius: 100px;
    background: #fff;
    border: 1px solid #a2a3a5;
    font-family: Gotham;
    font-size: 18px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #171a20;
    cursor: pointer;
}